*{
  box-sizing: border-box;
}

.TitleFont{
  font-family: 'Domine', serif;
}

.InterFont{
  font-family: 'Inter', sans-serif;
}

body{
  overflow: hidden;
  margin: 0;
  padding: 0;
  background-color: #FFFEF8;
}

.AppContainer{
  padding-top: 60px;
  padding-left: 150px;
  position: relative;
  height: 100vh;
  width: 100vw;
  margin: 0%;
}

.intro-copy{
  position: absolute;
  bottom: 60px;
  font-size: 2rem;
  width: 600px;
  line-height: 1.4em;
}

.NavBar{
  position: absolute;
  font-size: 1em;
  right: 150px;
  top: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: flex-start; 
}

a{
  text-decoration: none;
  color: black;
}

a:hover{
  font-weight: 600;
}

.NavBar div{
  margin-left: 20px;
}

#signature{
  width: 250px;
  margin-bottom: 2em;
}

.Footer{
  font-size: 1em;
  position: absolute;
  bottom: 60px;
  right: 150px;
}

.gallery-container{
  height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: scroll;
  align-items: left;
  justify-items: flex-start;
  scroll-behavior: smooth;
}

.gallery-container::-webkit-scrollbar {
  display: none;
}

.gallery-container img{
  width: 800px;
  outline: solid 1px black;
  outline-offset: -1px;
}

@media only screen and (max-width: 1200px) {
  .AppContainer {
    padding-left: 100px;
  }

  .NavBar{
    position: absolute;
    font-size: 1em;
    right: 100px;
    top: 60px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: end;
  }

  .gallery-container{
    width: 800px;
  }

  .NavBar div{
    margin-left: 0px;
  }

  .gallery-container img{
    width: 600px;
    outline: solid 1px black;
    outline-offset: -1px;
  }

}

@media only screen and (max-width: 970px) {

  body{
    overflow: scroll;
    padding-bottom: 4em;
  }
  .AppContainer {
    padding-left: 40px;
    width: 90%;
  }

  .NavBar{
    position: unset;
    right: 0;
    top: 20px;
    font-size: 1em;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: flex-start;
    gap: 20px;
    margin-bottom: 2em;
  }

  .NavBar div{
    margin-left: 0px;
  }

  .gallery-container{
    width: 800px;
  }

  .gallery-container img{
    width: 600px;
    outline: solid 1px black;
    outline-offset: -1px;
  }
  .intro-copy{
    width: auto;
    position: unset;
    bottom: 0;
  }
  #signature{
    width: 250px;
    margin-top: 2em;
    margin-bottom: 2em;
  }
  .Footer{
    position:unset;
    margin-top: 2em;
  }

  .gallery-container{
    width: auto;
  }

  .gallery-container img{
    width: auto;
  }

}


@media only screen and (max-width: 500px) {

  body{
    overflow: scroll;
    padding-bottom: 2em;
  }
  .AppContainer {
    padding-left: 40px;
    width: 90%;
  }

  .NavBar{
    position: unset;
    right: 0;
    top: 20px;
    font-size: 1em;
    display: flex;
    flex-direction: row;
    align-items: left;
    justify-content: center;
    gap: 50px;
    margin-bottom: 2em;
  }

  .NavBar div{
    margin-left: 0px;
  }

  .gallery-container{
    width: 800px;
  }

  .gallery-container img{
    width: 600px;
    outline: solid 1px black;
    outline-offset: -1px;
  }
  .intro-copy{
    width: auto;
    position: unset;
    bottom: 0;
    font-size: 1.5em;
  }
  #signature{
    width: 250px;
    margin-top: 4em;
    margin-bottom: 2em;
  }
  .Footer{
    position:unset;
    margin-top: 2em;
  }

  .gallery-container{
    width: auto;
  }

  .gallery-container img{
    width: auto;
  }

}